@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Electrolize&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

.space-mono {
  font-family: 'Space Mono', monospace;
}

.jost {
  font-family: "Jost";
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-1 {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.top-menu-item {
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.top-menu-item:hover {
  background-color: #333333;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 700;
}

.font-size-10 {
  font-size: 10rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.text-input {
  width: 70%;
  height: 2rem;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ffffff;
  padding: 1.5rem;
  background: none;
  font-size: 200%;
  text-decoration: none;
  font-weight: 500;
}

.text-input:focus {
  outline: none;
  border-bottom: 2px solid #ffffff;
}

.text-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc;
}

.margin-8 {
  margin: 8rem;
}

.margin-4 {
  margin-top: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.ml-200 {
  margin-left: 200px;
}

.width-100 {
  width: 100%;
  height: fit-content;
}

.center {
  align-items: center;
  justify-content: center;
}

.grayoverlay {
  filter: invert(76%) sepia(24%) saturate(5%) hue-rotate(49deg) brightness(102%)
    contrast(96%);
}

.color-white {
  color: #ffffff;
}

.social-icon {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
  padding: 0.5rem;
  text-decoration: none;
  border: none;
}

.social-icon-x {
  width: 2.6rem;
  height: 2.6rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
  padding: 0.5rem;
  text-decoration: none;
  border: none;
}

.social-icon:hover {
  background-color: #383636;
  border-radius: 0.2rem;
}

.layout {
  width: 100%;
  height: 100%;
  padding: 0.1rem;
}

.layout-title {
  font-size: 400%;
  opacity: 0.1;
  font-weight: bold;
  margin: 0;
  text-align: right;
  font-family: "Poppins";
}

.book-option {
  width: fit-content;
  height: fit-content;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
}

.book-option:hover {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
}

.book-title {
  font-family: "Satisfy";
  font-size: 120%;
  text-align: center;
  cursor: pointer;
}

.book-title-part {
  font-family: "Jost", sans-serif;
  font-size: 80%;
}

.align-center {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btn-lr {
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  cursor: pointer;
  background-color: #f0f0f0;
  opacity: 0.2;
  margin: 1rem;
  border-radius: 50%;
  font-size: 200%;
  visibility: hidden;
}

.btn-bookbuy {
  background-color: #e5a673;
  width: 15rem;
  height: auto;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 5px 5px 5px #999999;
  text-decoration: none;
  margin: 1rem;
}

.btn-bookbuy > a,
.btn-bookbuy > a:visited,
.btn-bookbuy > .a:link {
  color: #191919;
  text-decoration: none;
}

.option-input {
  width: 10rem;
  height: auto;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  border-radius: 0.2rem;
  margin: 0.5rem;
  padding: 0.5rem;
  font-family: "Jost", "sans-serif";
  font-size: 110%;
}


.title-font-1 {
cursor: pointer;
font-size: 400%;
text-align: right;
font-family: "Space Mono";
color: white;
text-Shadow: 1px 1px #ff0707;
}

.option-input:focus {
  outline: none;
}

.align-left {
  text-align: left;
}

.items-title {
  font-size: 120%;
  font-weight: bold;
  margin: 0 0 0.5rem 1rem;
  text-align: left;
}

.margin-4 {
  margin: 4rem;
}

.margin-2 {
  margin: 2rem;
}

.margin-lr-4 {
  margin: 0 10rem 0 10rem;
}

.about-book {
  background-color: #ffffff;
  width: 100% - 100rem;
  height: auto;
  padding: 0.7rem;
  border-radius: 0.5rem;
  margin: 2rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.about-book-header {
  font-weight: bold;
  font-size: 105%;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.flex-wh-yt {
  width: 20%;
  height: 10rem;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-wh-yt {
    width: 100%;
    height: 1000rem;
  }
}

.flex-wh-sp {
  width: 30%;
  height: 10rem;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-wh-sp {
    width: 100%;
    height: 100rem;
  }
}

.items-bg {
  width: 90rem;
  max-height: 22rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  overflow-y: scroll;
  overflow-x: scroll;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 800px) {
  .items-bg {
    width: 95%;
    max-height: 22rem;
  }
}

.blogList::-webkit-scrollbar {
  width: 0.1rem; /* Remove scrollbar space */
  background: #111111; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}

.blogList::-webkit-scrollbar-thumb {
  background: #555555; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.text-decoration {
  text-decoration: none;
  color: #191919;
}

.blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blogList {
  overflow-y: scroll;
  height: 300px; /* Change this to the desired height */
  -webkit-overflow-scrolling: touch;
}

.blogTh {
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  margin: 1rem;
  background-color: #333333;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 10px 0px #191919;
}

.blogTh:hover {
  background-color: #444444;
}

.blogThTitle {
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  font-size: 1.2rem;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 2px #000000;
}

.blogThTitle:hover {
  color: #eeeeee;
}

.blogThSub {
  font-size: 0.9rem;
  text-align: left;
  margin: 0.5rem 0.1rem;
  color: #999999;
  transition: all 0.2s ease-in-out;
  text-shadow: 1px 1px 1px #000000;
}

.blogThSub:hover {
  color: #cccccc;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 768px) {

  .blog-container {
    display: flex;
    align-items: left;
    justify-content: left;
    width: 80%;
  }

  .blogTh {
    width: 100%;
  }

  .blogThTitle {
    font-size: 1rem;
  }

  .blogThSub {
    font-size: 0.8rem;
  text-align: left;
  margin: 0.5rem 0.1rem;
  }
  .blogTh a {
    color: #ffffff;
    text-decoration: none;
  }

  .blogTh a:hover {
    color: #eeeeee;
  }

  .blogTh a:active {
    color: #dddddd;
  }
}

.design-item-name {
  font-weight: bold;
}

.design-item-image {
  width: 100px;
  height: auto;
  background-color: transparent;
  border-radius: 0.3rem;
}

.design-item-image-bg {
  width: 10rem;
  height: 8rem;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  cursor: pointer;
}

.design-item-image-bg:hover {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.absolute {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 99;
}

/* card.css */
.card-dp {
  width: 345px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px #000000;
  display: inline-block;
  margin: 16px;
  padding: 5px;
  text-align: center;
  position: relative;
  background: #191919;
  border-bottom: 2px solid #2ade8a;
}

.media-dp {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.title-dp {
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0;
  color: #2ade8a;
}

.desc-dp {
  font-size: 16px;
  margin: 8px 0;
  color: #fff;
}

.text-dp {
  font-size: 12px;
  margin: 8px 0;
  color: #fff;
}

.link-dp {
  color: black;
  text-decoration: none;
  margin: 8px;
  color: #2d918e;
  cursor: pointer;
}

/* card.css */
.card-f {
  width: 250px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #ffffff;
  display: inline-block;
  margin: 16px;
  padding: 7px;
  text-align: center;
  position: relative;
  background: #191919;
  border-bottom: 2px solid #2ade8a;
}

.media-f {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.title-f {
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0;
  color: #d70c6b;
}

.desc-f {
  font-size: 16px;
  margin: 8px 0;
  color: #fff;
}

.text-f {
  font-size: 12px;
  margin: 8px 0;
  color: #fff;
}

.link-f {
  color: black;
  text-decoration: none;
  margin: 8px;
  color: #43c6d5;
}

/* card.css */
.card-at {
  width: 350px;
  border-radius: 4px;
  box-shadow: inset 0px 0px 10px #000000;
  display: inline-block;
  margin: 16px;
  padding: 50px 10px 80px 10px;
  text-align: center;
  position: relative;
  background: #191919;
  border-bottom: 2px solid #f5e89f;
}

.media-at {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.title-at {
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0;
  color: #ffd700;
  margin-bottom: 50px;
}

.desc-at {
  font-size: 22px;
  margin: 8px 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-style: bold;
}

.text-at {
  font-size: 12px;
  margin: 8px 0;
  color: #fff;
}

.link-at {
  color: black;
  text-decoration: none;
  margin: 8px;
  color: #7ae8f4;
}

/* card.css */
.card-ex {
  width: 350px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #000000;
  display: inline-block;
  margin: 16px;
  padding: 50px 10px 80px 10px;
  text-align: center;
  position: relative;
  background: #191919;
  border: 2px dotted #458187;
}

.media-ex {
  height: 140px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.title-ex {
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0;
  color: #ffd700;
  margin-bottom: 50px;
}

.desc-ex {
  font-size: 22px;
  margin: 8px 0;
  color: #fff;
}

.text-ex {
  font-size: 12px;
  margin: 8px 0;
  color: #fff;
}

.link-ex {
  color: black;
  text-decoration: none;
  margin: 8px;
  color: #7ae8f4;
}

.card-dp {
  width: 345px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px #000000;
  display: inline-block;
  margin: 16px;
  padding: 5px;
  text-align: center;
  position: relative;
  background: #191919;
  border-bottom: 2px solid #2ade8a;
}

/* Base styles for the Crypto component */
.card-tb {
  width: 345px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px #000000;
  display: inline-block;
  margin: 16px;
  padding: 5px;
  text-align: center;
  position: relative;
  background: #191919;
  border-bottom: 2px solid #2ade8a;
}

.layout {
  padding: 20px;
}

.lay {
  overflow-y: scroll;
  height: 400px; /* Change this to the desired height */
  -webkit-overflow-scrolling: touch;
}

.lay::-webkit-scrollbar {
  width: 0.1rem; /* Remove scrollbar space */
  background: #111111; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}

.lay::-webkit-scrollbar-thumb {
  background: #555555; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}

/* Responsive styles for small screens */
@media only screen and (max-width: 600px) {
  .layout {
    font-size: 14px;
  }
  .card-tb {
    width: 70%;
    padding: 10px;
  }
}

/* Responsive styles for medium screens */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .layout {
    font-size: 16px;
  }
  .card-tb {
    width: 60%;
    padding: 15px;
  }
}

/* Responsive styles for large screens */
@media only screen and (min-width: 900px) {
  .layout {
    font-size: 18px;
  }
  .card-tb {
    width: 720px;
    padding: 20px;
  }
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  background: #050505;
  color: white;
  padding: 2em;
  width: 300px;
  box-shadow: 0px 0px 10px #ccc;
}

.card-header {
  text-align: center;
}

.card-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.form-group input::placeholder, .form-group textarea::placeholder {
  color: #333333;
}


label {
  width: 100%;
}

input,
textarea {
  width: 100%;
  padding: 0.5em;
  border: none;
  border-radius: 4px;
  margin-top: 0.5em;
  background-color: #191919;
  resize: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 100%;
}

input {
  height: 20px;
}

textarea {
  height: 100px;
}

button {
  background: #111311;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  margin-top: 1em;
  cursor: pointer;
  font-size: 120%;
}

button:hover {
  background: #181c29;
}

.gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #d93333, #181211);
}

.box-white {
  border: #ffffff solid 1px;
  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
}

.ascii-art {
  font-family: monospace;
  background-color: #191919;
  color: #ffffff;
  white-space: pre;
  cursor: pointer;
}

.font-80 {
  font-size: 50%;
}

.font-100 {
  font-size: 100%;
}

.responsive-img {
  width: auto;
  max-width: 100%;
}

@media (max-width: 768px) {
  .responsive-img {
    width: 100%;
    height: auto;
  }
}

.responsive-img-1 {
  width: 600px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .responsive-img-1 {
    width: 100%;
    height: auto;
  }
}


.tag-font {
  color: #ffffff;
  opacity: 0.1;
  font-family: monospace;
  font-size: 120%;
}

.ascii-art:hover {
  color: #e5e110;
}

.box-white:hover {
  background-color: #ffffff;
  color: #191919;
}

.box-white1 {
  background-image: radial-gradient(circle at center, #ffffff, #d6d6d6);
  color: #191919;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  display: flex;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
  margin: 5px auto;
  justify-content: center;
  box-shadow: 0px 0px 20px 5px rgba(203, 203, 206, 0.2);
}

.hover5:hover {
  border-radius: 200px;
}

.hover1:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.5);
  box-shadow: 0px 0px 10px #999;
}

.hover2:hover {
  filter: blur(5px);
}

.hover3:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid #333;
}

.hover4:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-50%) scale(2) rotate(0deg);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2),
    inset 0 0 0 10px rgba(255, 255, 255, 0.5);
  cursor: grab;
}

.card-am {
  width: 350px;
  padding: 20px;
  border: 1px dotted #458178;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #000000;
  color: #fff;
}

.card-title-am {
  margin-top: 0;
}

.card-text-am {
  line-height: 1.5;
}

.container-am {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.youtube-wh {
width: 560px;
height: 315px;
}

.youtube-video-container {
  display: flex; /* This will make the container a flex container */
}

.youtube-video-container iframe {
  border-radius: 5px;
}

.youtube-video-description {
  width: 700px;
  margin-left: 20px; /* add some margin to the description */
  flex: 1; /* This will make the description take up all remaining space */
  color: #fff;
  text-align: left;
}

.youtube-video-description-container {
  padding: 10px;
  border-radius: 5px;
  margin-left: 20px;
}

.ytlink {
  color: rgb(142, 207, 229);
  text-decoration: none;
}

.youtube-video-container {
  display: flex;
  align-items: center;
}

.spotify-audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.sp-desc {
  width: 1400px;
  margin-left: 20px; /* add some margin to the description */
  color: #fff;
}

.sp-desc-container {
  display: flex;
  align-items: center;
  width: 1400px;
  justify-content: center;
}

.spotify-audio-container iframe {
  border-radius: 0px;
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
}
.top-row,
.bottom-row {
  display: flex;
  width: 95%;
}
.top-row img,
.bottom-row img {
  width: 20%;
  cursor: pointer;
}

.book-container {
  display: flex;
}
.book-image-container {
  flex: 1;
}

.book-image {
  width: 50%;
}

.book-info-container {
  flex: 1;
  padding: 16px;
}
.book-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.m-5 {
  margin: 5px;
}

/* card.css */
.card-co {
  width: 345px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px #000000;
  display: inline-block;
  margin: 16px;
  padding: 5px;
  text-align: center;
  position: relative;
  background: #191919;
  border-left: 1px solid #de9b00;
}

.media-co {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.title-co {
  font-size: 24px;
  margin: 8px 0;
  color: #de9b00;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.desc-co {
  font-size: 16px;
  margin: 8px 0;
  color: #fff;
}

.text-co {
  font-size: 12px;
  margin: 8px 0;
  color: #fff;
}

.link-co {
  text-decoration: none;
  margin: 8px;
  color: #b18a4f;
  font-size: 90%;
  font-weight: 100;
}

.modal-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: #191919;
  border-radius: 5px;
  width: 70%;
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.modal-content {
  margin-top: 20px;
  color: #fff;
  max-height: 500px;
  overflow-y: auto;
}

.modal-contact {
  position: relative;
  background-color: #191919;
  border-radius: 5px;
  width: 50%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.zerogap {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 700px;
}

@media only screen and (min-width: 768px) {
  .zerogap {
    margin: 0;
    padding: 0;
    width: 90%;
    max-width: 700px;
  }
}

@media only screen and (min-width: 992px) {
  .zerogap {
    margin: 0;
    padding: 0;
    width: 80%;
    max-width: 700px;
  }
}

@media only screen and (min-width: 1200px) {
  .zerogap {
    margin: 0;
    padding: 0;
    width: 70%;
    max-width: 700px;
  }
}


.centerimages {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.width50 {
  width: 50%;
}

@media (max-width: 768px) {
  /* Adjust font sizes */
  .font-size-10 {
    font-size: 5rem;
  }
  .font-size-1-2 {
    font-size: 1rem;
  }

  .font-80 {
    font-size:0.3rem;
  }

  .font-100 {
    font-size: 0.75rem;
  }

  .top-menu-item {
    padding: 0.4rem;
    margin: 0.3rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: #ffffff;
    font-size: 80%;
  }

  .flex-start {
    display: flex;
    align-items: start;
    margin: 0;
    padding: 0;
  }

  .absolute {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 99;
    margin: 0 auto;
  }

  .youtube-wh {
    width: 300px;
    height: 180px;
    }

  .title-font-1 {
    cursor: pointer;
    font-size: 300%;
    text-align: center;
    font-family: "Space Mono";
    color: white;
    text-Shadow: 1px 1px #ff0707;
    }
  
  /* Adjust margins and padding */
  .margin-8 {
    margin: 4rem;
  }
  .margin-4 {
    margin-top: 2rem;
  }
  
  /* Modify input width */
  .text-input {
    width: 90%;
  }
}

@media (max-width: 576px) {
  /* Adjust font sizes */
  .font-size-10 {
    font-size: 3rem;
  }
  .font-size-1-2 {
    font-size: 0.8rem;
  }
  
  /* Adjust margins and padding */
  .margin-8 {
    margin: 2rem;
  }
  .margin-4 {
    margin-top: 1rem;
  }
  
  /* Modify input width */
  .text-input {
    width: 100%;
  }
}

/* For screens with a maximum width of 768px */
@media only screen and (max-width: 768px) {
  .flex-row {
    flex-direction: column;
  }

  .ml-200 {
    margin-left: 0;
  }

  .flex-row-1 {
    flex-direction: row;
  }
  
  .margin-8 {
    margin: 2rem;
  }
  
  .book-option {
    font-size: 90%;
  }
  
  .btn-bookbuy {
    width: 100%;
  }
}

/* For screens with a minimum width of 768px */
@media only screen and (max-width: 768px) {
  .layout {
    display: flex;
    flex-direction: column;
  }

  .center {
    align-items: center;
    justify-content: center;
  }
  
  .layout-title {
    font-size: 3rem;
    text-align: left;
  }

  .small-text {
    font-size: 2.5rem;
  }
  
  .book-option {
    font-size: 100%;
  }
  
  .btn-lr {
    visibility: visible;
  }
  
  .btn-bookbuy {
    width: auto;
  }
}

@media (max-width: 800px) {
  .option-input {
    width: 100%;
    margin: 0.5rem 0;
  }

  .margin-4,
  .margin-2,
  .margin-lr-4 {
    margin: 2rem;
  }

  .about-book {
    width: 100%;
  }

  .flex-wh-yt,
  .flex-wh-sp {
    width: 100%;
    height: auto;
  }

  .items-bg {
    width: 100%;
    max-height: 22rem;
  }

  .blogList {
    height: auto;
  }

  .blogTh {
    margin: 0.5rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .card-dp, .card-f, .card-at {
    width: 70%;
    margin: 16px auto;
  }

  .title-dp, .title-f, .title-at {
    font-size: 20px;
  }

  .desc-dp, .desc-f, .text-dp, .text-f {
    font-size: 14px;
  }
}

/* Responsive styles for small screens */
@media only screen and (max-width: 600px) {
  .layout {
    font-size: 14px;
  }
  .card-tb {
    width: 70%;
    padding: 10px;
  }
  .card {
    width: 70%;
  }
}

/* Responsive styles for medium screens */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .layout {
    font-size: 16px;
  }
  .card-tb {
    width: 60%;
    padding: 15px;
  }
}

/* Responsive styles for large screens */
@media only screen and (min-width: 900px) {
  .layout {
    font-size: 18px;
  }
  .card-tb {
    width: 720px;
    padding: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .card-container {
    height: 120vh;
  }
}

@media only screen and (max-width: 400px) {
  .card-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-group {
    margin: 1em 0;
  }
}

@media screen and (max-width: 768px) {
  /* styles for screens with max width of 768px */
  .card-am {
    width: 80%;
  }
  
  .youtube-video-description {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  
  .sp-desc {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .sp-desc-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  
  .photo-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .top-row,
.bottom-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
}
.top-row img,
.bottom-row img {
  width: 20%;
  cursor: pointer;
}
}

@media screen and (max-width: 480px) {
  /* styles for screens with max width of 480px */
  .gradient {
    font-size: 100%;
  }
  
  .card-am {
    width: 100%;
    padding: 10px;
  }
  
  .youtube-video-container {
    flex-direction: column;
  }
  
  .youtube-video-description-container,
  .spotify-audio-container {
    margin-left: 0;
  }
  
  .spotify-audio-container iframe {
    width: 100%;
    height: 100%;
  }
  
  .sp-desc {
    width: 100%;
    font-size: 80%;
    margin-top: 20px;
  }
  
  .top-row img,
  .bottom-row img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  /* Add this code to adjust the book-info-container and book-image-container on larger screens */
  .book-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }


  .book-info-container {
    flex: 1;
    padding: 16px;
    max-width: 75%;
  }

  /* Add this code to adjust the card-co on larger screens */
  .card-co {
    width: 250px;
    margin: 16px 8px;
  }

  /* Add this code to adjust the title-co on larger screens */
  .title-co {
    font-size: 24px;
  }
}